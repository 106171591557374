<template><div>...Loading</div></template>
<script>
import { getInstance } from "@/auth";

export default {
  name: "Callback",
  mounted() {
    const authService = getInstance();

    const getToken = async () => {
      const claims = await authService.getIdTokenClaims();
      if (claims) {
        const token = claims.__raw;
        this.$store.commit("setToken", token);
        this.$store.commit(
          "setUserRole",
          claims["https://hasura.io/jwt/claims"]["x-hasura-role"]
        );
        this.$store.commit(
          "setUserID",
          claims["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
        );
        this.$store.commit('setUser', claims)

        window.localStorage.setItem("token", token);
        await this.$router.push("/");

      }
    };

    // If loading has already finished, check our auth state using `getToken()`
    if (!authService.loading) {
      return getToken();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", (loading) => {
      if (loading === false) {
        return getToken();
      }
    });
  },
};
</script>
